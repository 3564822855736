window.isOptionSelected = function(item, value) {
  if (value === item) {
    return "selected='selected'";
  } else {
    return "";
  }
}

window.optionGenerator = function(arr, value) {
  arr = $.map(arr, function(item) {
    return "<option value='" + item + "' " + isOptionSelected(item, value) + ">" + item + "</option>";
  });
  return arr.join("");
}

window.valueOptionGenerator = function(arr, value, display, selected, blank, choose_text) {
  var blank_option, choose_option, options;
  arr = $.map(arr, function(item) {
    return "<option value='" + item[value] + "' " + (value === item ? "selected='selected'" : "") + ">" + item[display] + "</option>";
  });
  options = arr.join("");
  if (blank === true) {
    blank_option = "<option value=''></option>";
  }
  if (choose_text != null) {
    choose_option = "<option value=''>" + choose_text + "</option>";
  }
  return choose_option + blank_option + options;
}
